import React from "react"
import Layout from "../components/layout"
import Home from "../components/Home"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Amplify from "aws-amplify"
import awsconfig from "../utils/updateAmplifyConfig"

Amplify.configure(awsconfig)

const IndexPage = ({ data }) => {
  const { nodes } = data.allProject
  console.log({ nodes })
  return (
    <Layout>
      <SEO title="Bienvenue sur Hervé.shop" />
      <Home projects={nodes} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allProject {
      nodes {
        id
        slug
        name
        meta {
          city
          zip_code
          url
          description
          fullDescription
          background_url
          logo_url
        }
        background_url {
          childImageSharp {
            gatsbyImageData
          }
        }
        logo_url {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
